@import 'src/lib/_vars.scss';


.header {
   padding: 1.75em 0px 1.75em 0px;
   width: 100%;
   background-color: $black;

   .header-content {
      background-color: $black;
      max-width: 800px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;

      .left {
         display: flex;

         .logo {
            max-width: 173px;
            width: 10.81em;
         }
      }

      .right {
         //width: 557px;
         width: 34.8em;

         h1 {
            font-family: 'ObjectSans';
            font-weight: normal;
            font-size: 3.93em;
            color: #FFFFFF;
            letter-spacing: 0;
            margin: 0;
         }

         h3 {
            font-family: 'SupplyMono';
            font-size: 1.31em;
            line-height: 1.9em;
            color: #FFFFFF;
            letter-spacing: 0;
            margin: 0;
         }
      }
   }
}

.footer {
   background-color: $black;
   padding-top: 1em;
   padding-bottom: 1em;
   text-align: center;
   font-family: "SupplyMono";
   font-size: 13px;
   color: #757575;
   letter-spacing: 0;
}

.main {
   margin: 0 auto;;
   max-width: 800px;
   width: 100%;

   .form-container {
      margin: 1.6em 0 1.8em 0;

      .email-feedback-shown {
         display: block !important;
      }

      .email-feedback {
         display:none;
         text-align:center;
         background-color: $black;
         font-family: 'SupplyMono';
         font-size: 16px;
         line-height: 25px;
         color: $window-red;
         letter-spacing: 0;
      }

      form {
         .inline {
            position: relative;
            width: 100%;
            //margin: 1.6em 0 1.8em 0;
            display: flex;
            align-items: center;

            input {
               background: $white;
               border: 2px solid rgba(0,0,0,.9);
               border-radius: 2px;
               padding: 1em 8.5em 0.75em 0.7em;
               width: 100%;
               box-sizing: border-box;
               box-shadow: none;
               margin: 0;

               font-family: 'SupplyMono';
               font-size: 1.25em;
               color: #757575;
               letter-spacing: 0;
            }

            button {
               position: absolute;
               right: 0;
               background: rgba(0,0,0,.9);
               color: rgba(255,255,255,.9);
               border: none;
               cursor: pointer;
               padding: 0.5em 0.75em 0.56em 0.75em;
               margin: 0 .7em 0 0;
               border-radius: 2px;

               font-family: 'ObjectSans';
               font-weight: bold;
               font-size: 1.375em;
               color: #FFFFFF;
               letter-spacing: 0;

               .greyed {
                  color:#757575;
               }

               .processing {
                  display:none;
               }
            }

         }
      }
   }

   .captcha-block {
      padding-bottom: 25px;
      display: none;
      justify-content: center;
   }


   .success a {
      color: $yellow;
      text-decoration: none;      
   }

   .success a:hover {
      color: $yellow;
      text-decoration: underline;   
   }

   .show-flex {
      display:flex !important;
   }

   .shown {
      display: block !important;
   }

   .hidden {
      display: none !important;
   }

   .success {
      text-align:center;
      background-color: $black;
      font-family: 'SupplyMono';
      font-size: 16px;
      line-height: 25px;
      color: $window-green;
      letter-spacing: 0;
      margin-bottom: 25px;
      padding: 5px 5px 5px 5px;
      display: none;
   }

   .body-copy {
      font-family: 'ObjectSans';
      font-size: 1.875em;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 1.7em;
   }

   .genome {
      width: 14.625em;
      display: flex;
      justify-content: space-evenly;
      height: auto;
      margin: 0 auto;
      padding-bottom: 2.375em;
      padding-top: 2.375em;
      align-items: center;
   
      .avatar {
         display: flex;
      }
   
      .name {
         font-family: 'ObjectSans';
         font-size: 1em;
         width: 7.75em;

         a {
            text-decoration: underline;
            color: $white;
         }

         a:hover {
            color: $yellow;
            text-decoration: none;
         }
      }
   }

   .issue-button {
      padding-bottom: 50px;

      button {
         background: $yellow;
         border-radius: 19px;
         border: 0;
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin: 0 auto;
         padding: 0;
         
         .title {
            font-family: "ObjectSans";
            font-size: 12px;
            color: #1B1B1B;
            letter-spacing: 0;
            text-align: center;
            display: flex;
            padding-left: 19px;
            padding-right: 12px;
            line-height: 35px;

         }

         .down-btn {
            display: flex;
         }



      }
   }


   .issue-archive {
      max-width: 800px;
      width: 100%;
      background: #1B1B1B;
      border-radius: 11px;
   
      .window {
         width: 100%;
         display: flex;
         justify-content: space-between;
         align-items: center;

         .window-block { 
            width: 69px;
            display:flex;
         }

         .window-title {
            display: flex;
            font-family: 'SupplyMono';
            font-size: 13px;
            color: #757575;
            letter-spacing: 0;
         }

         .window-buttons {
            display: flex;
            justify-content: space-between;
            margin:0.9em;
            //padding-top: 15px;
            width: 4.31em;
      
            div {
               width: 15px; 
               height: 15px;
               border-radius: 30px;
               display: flex;   
            }
      
            .red { background: #FE5F58; }
            .yellow { background: #FEBC30; }
            .green { background: #26C940; }
         }

      }
      .email-preview { 
         position: relative;
         border-radius: 11px;
         width: 100%;

         .bottom-gradient {
            background: linear-gradient(rgba(12,80,255,0), rgba(12,80,255,1));
            height:30em;
            width: 100%;
            z-index: 10;
            bottom: 0px;
            position: absolute;
         }

         .mail-preview-image {
            max-width: 800px;
            width: 100%;
         }

         .archive-button {
            span {
               background-color: $black;
               color: $yellow;
            }

            position: absolute;
            top: 22.5em;
            z-index: 5;
            display:inline-table;
            padding: 1.25em;
            background: rgba(0,0,0,.9);
            color: rgba(255,255,255,.9);
            border: none;
            background: url('../../assets/staticbg.png');

            //cursor: pointer;
            font-family: 'ObjectSans';
            font-weight: bold;
            font-style: italic;
            font-size: 1.375em;
            line-height: 2em;
            color: #FFFFFF;
            letter-spacing: 0;
            //text-decoration: underline;

            a {
               color: #fff;
               text-decoration: none;
            }

            a:hover {
               text-decoration: none;
            }

         }
   
         
      }
   
   }
} //MAIN





