$black: #1B1B1B;
$white: #fff;
$yellow: #FEBC30;
$internet-blue: #0C50FF;

$window-red: #FE5F58;
$window-yellow: #FEBC30;
$window-green: #26C940;

$ease-standard:   cubic-bezier(.4,0,.2,1);
$ease-fade:       cubic-bezier(.4,0,.6,1);
$ease-enter:      cubic-bezier( 0,0,.2,1);
$ease-exit:       cubic-bezier(.4,0, 1,1);

