@import 'src/lib/_vars.scss';

* { box-sizing: border-box;  }

html {
  font-size: 16px;
  font-size: calc(9px + (16 - 9) * ((100vw - 375px) / (800 - 375)));
  font-family: HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Fluid font size:
minimum font size at min. device width 300px = 14
maximum font size at max. device width 1600px = 26
*/
/*
html {
  font-size: calc(14px + (16 - 14) * ((100vw - 300px) / (1600 - 300)));
}
*/

@media screen and (max-width: 375px) {
  html {
    //font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    font-size: calc(9px + (16 - 9) * ((100vw - 375px) / (800 - 375)));
  }

  .header-content {
    display: block !important;

    .left {
      //display: flex;
      margin: 0 auto;
      width: 100%;
      justify-content:space-around;
    }

    .right {
      width:100% !important;
      text-align: center;
      h3 {
        width: 98%;      
      }
    }
  }

  .form-container {
    margin-left: 6px;
    margin-right: 6px;
  }

  .body-copy {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 2.0em !important;
    text-align: center;
  }
  .issue-button {
    .title {
      padding-left: 10px !important;;
      padding-right:10px !important;;
    }

    .down-btn {
      display:none !important;
    }
  }

  .issue-archive .window .window-buttons, .window-block {
    width: 3.31em !important;
  }

  .issue-archive .window .window-buttons div {
    width: 8px !important;
    height: 8px !important;
    border-radius: 30px;
    display: flex;

  }

  .issue-archive .window .window-title {
    font-size:10px !important;
  }


  .genome {
    width: 24em !important;

    .name {
      font-size: 1.45em !important;

    }
  }

} //iphone




@media screen and (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}


body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: $white;
    background-color: $internet-blue;
    -webkit-font-smoothing: antialiased;
    //overflow: hidden;
   // font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
}

.not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input, textarea, select, button {
    font-family: ember;
    cursor: pointer;
}

a { cursor: pointer; }

img {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    display: block;
}

@font-face {
  font-family: 'ObjectSans';
  src: url(../assets/fonts/PPObjectSans-Bold.woff2) format('woff2'),
    url(../assets/fonts/PPObjectSans-Bold.woff) format('woff'),
    url(../assets/fonts/PPObjectSans-Bold.ttf) format('truetype');
  font-style:normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'ObjectSans';
  src: url(../assets/fonts/PPObjectSans-BoldSlanted.woff2) format('woff2'),
    url(../assets/fonts/PPObjectSans-BoldSlanted.woff) format('woff'),
    url(../assets/fonts/PPObjectSans-BoldSlanted.ttf) format('truetype');
  font-style:italic;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'ObjectSans';
  src: url(../assets/fonts/PPObjectSans-Heavy.woff2) format('woff2'),
    url(../assets/fonts/PPObjectSans-Heavy.woff) format('woff'),
    url(../assets/fonts/PPObjectSans-Heavy.ttf) format('truetype');
  font-style:normal;
  font-weight: bolder;
  font-display: swap;
}

@font-face {
  font-family: 'SupplyMono';
  src: url(../assets/fonts/PPSupplyMono-Regular.woff2) format('woff2'),
  url(../assets/fonts/PPSupplyMono-Regular.woff) format('woff'),
  url(../assets/fonts/PPSupplyMono-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}


.page-container {
 width: 100%;
}
/*
.content-container {
  display: flex;
  align-items: center;
  
  width: 800px;
}
*/

